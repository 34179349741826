<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-card-title class="justify-center success--text" >
        {{titulo}}
      </v-card-title>
      <v-divider class="mx-8"></v-divider>
      <v-card-text class="mt-6 mb-2 text-center">
        {{mensaje}}
      </v-card-text>
      <v-divider class="mx-8 mb-4"></v-divider>
      <v-card-text class="text-center">
        <v-btn outlined color="success" @click="hideDialog"> Entendido </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalMensajeNuevasFunciones",
  props: {
    titulo:{default:""},
    mensaje:{default:""},
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>